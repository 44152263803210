import {
  IDailyExerciseQuery,
  IDailyExerciseTableData,
} from "./../../shared/types/exercise";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import { IMessageResponse, credential } from "../../shared";
import { IExercisesTableData } from "../../screens/AdminPanel/Exercises/IndividualExercisesTable/individualExercisesTable.interface";

export const DAILY_EXERCISES_API_REDUCER_KEY = "dailyExercisesApi";

export const dailyExerciseApi = createApi({
  reducerPath: DAILY_EXERCISES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [DAILY_EXERCISES_API_REDUCER_KEY],
  endpoints: (build) => ({
    fetchDailyExercise: build.query<
      IDailyExerciseTableData[],
      IDailyExerciseQuery
    >({
      query: (params) => ({
        url: PathBuilder.dailyExerciseApi.fetchDailyExercise(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [DAILY_EXERCISES_API_REDUCER_KEY],
    }),
    updateDailyExercise: build.mutation<IMessageResponse, IExercisesTableData>({
      query: (params) => {
        const { _id: id, ...newParams } = params;
        return {
          url: PathBuilder.dailyExerciseApi.updateDailyExercise(id as string),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [DAILY_EXERCISES_API_REDUCER_KEY],
    }),
    fetchDailyPatientExercise: build.query<
      IDailyExerciseTableData[],
      IDailyExerciseQuery
    >({
      query: (params) => ({
        url: PathBuilder.dailyExerciseApi.fetchDailyPatientExercise(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [DAILY_EXERCISES_API_REDUCER_KEY],
    }),
    updateDailyPatientExercise: build.mutation<
      IMessageResponse,
      IExercisesTableData
    >({
      query: (params) => {
        const { _id: id, ...newParams } = params;
        return {
          url: PathBuilder.dailyExerciseApi.updateDailyPatientExercise(
            id as string
          ),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [DAILY_EXERCISES_API_REDUCER_KEY],
    }),
  }),
});
