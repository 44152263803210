export const userApi = {
  createUser: (): string => `/api/admin/users`,
  updateUser: (userId: string): string => `/api/admin/users/${userId}`,
  deactivateUser: (userId: string): string =>
    `/api/admin/users/${userId}/deactivate`,
  activateUser: (userId: string): string =>
    `/api/admin/users/${userId}/activate`,
  fetchWorkerList: (): string => `/api/public/users`,
  fetchWorker: (userId: string): string => `/api/public/users/${userId}`,
  fetchPatientList: (): string => `/api/portal/staff/patients`,
  fetchPatient: (userId: string): string =>
    `/api/portal/staff/patients/${userId}`,
  createPatient: (): string => `/api/portal/staff/patients`,
  updatePatient: (userId: string): string =>
    `/api/portal/staff/patients/${userId}`,
  fetchCurrentUser: (): string => `/api/portal/users/me`,
  updateCurrentUser: (): string => `/api/portal/users/me`,
};
