import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  IArticle,
  IArticleQuery,
  IArticleResponse,
  IMessageResponse,
  credential,
} from "../../shared";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import { ReducerKeys } from "../types";

export const articleApi = createApi({
  reducerPath: ReducerKeys.ARTICLES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.ARTICLES_API_REDUCER_KEY],
  endpoints: (build) => ({
    createArticle: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.articleApi.createArticle(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.ARTICLES_API_REDUCER_KEY],
    }),
    updateArticle: build.mutation<
      IArticle,
      { params: FormData; articleId: string }
    >({
      query: (data) => {
        const { articleId, params } = data;

        return {
          url: PathBuilder.articleApi.updateArticle(articleId),
          method: RequestTypes.put,
          data: params,
        };
      },
      invalidatesTags: [ReducerKeys.ARTICLES_API_REDUCER_KEY],
    }),
    deleteArticle: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.articleApi.deleteArticle(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.ARTICLES_API_REDUCER_KEY],
    }),
    fetchArticleList: build.query<IArticleResponse, IArticleQuery>({
      query: (params) => ({
        url: PathBuilder.articleApi.fetchArticleList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.ARTICLES_API_REDUCER_KEY],
    }),
    fetchArticle: build.query<IArticle, string>({
      query: (id) => ({
        url: PathBuilder.articleApi.fetchArticle(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.ARTICLES_API_REDUCER_KEY],
    }),
  }),
});
