export const filesApi = {
  postFileByStaff: (): string => `/api/portal/staff/files`,
  fetchFilesByStaff: (): string => `/api/portal/staff/files`,
  fetchFileByStaff: (fileId: string): string =>
    `/api/portal/staff/files/${fileId}`,
  updateFileByStaff: (fileId: string): string =>
    `/api/portal/staff/files/${fileId}`,
  deleteFileByStaff: (fileId: string): string =>
    `/api/portal/staff/files/${fileId}`,
  postFileByPatient: (): string => `/api/portal/patient/files`,
  fetchFilesByPatient: (): string => `/api/portal/patient/files`,
  fetchFileByPatient: (fileId: string): string =>
    `/api/portal/patient/files/${fileId}`,
};
