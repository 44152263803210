import React, { FC } from "react";
import styles from "./tableDeleteButton.module.scss";
import { delete_icon } from "@jat-med/assets";
import { ITableDeleteButtonProps } from "./tableDeleteButton.interface";
import { useTranslation } from "react-i18next";

export const TableDeleteButton: FC<ITableDeleteButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button
      title={t("buttons.cancel-visit")}
      className={styles.table_delete_button}
      onClick={onClick}
    >
      <img src={delete_icon} alt="delete_btn" />
    </button>
  );
};
