export const serviceApi = {
  createService: (): string => `/api/admin/services`,
  updateService: (serviceId: string): string =>
    `/api/admin/services/${serviceId}`,
  fetchServiceList: (): string => `/api/public/services`,
  fetchService: (serviceId: string): string =>
    `/api/public/services/${serviceId}`,
  activateService: (serviceId: string): string =>
    `/api/admin/services/${serviceId}/activate`,
  deactivateService: (serviceId: string): string =>
    `/api/admin/services/${serviceId}/deactivate`,
};
