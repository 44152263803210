import moment from "moment";

export const getTimeNumbersInArray = (time?: string) => {
  if (!moment(time).isValid()) {
    const currentDate = moment();
    return currentDate
      .set({
        hour: moment(time, "HH:mm").hour(),
        minute: moment(time, "HH:mm").minute(),
        second: 0,
        millisecond: 0,
      })
      .format("HH:mm")
      .toString()
      .split(":")
      .map(Number);
  } else {
    return moment(time).format("HH:mm").toString().split(":").map(Number);
  }
};
