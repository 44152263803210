import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any[] = [];

export const dailyExercisesSlice = createSlice({
  name: "dailyExercisesReducer",
  initialState,
  reducers: {
    setExercisesTableData: (state, action: PayloadAction<any[]>) => {
      return action.payload;
    },
  },
});

export const { setExercisesTableData } = dailyExercisesSlice.actions;

export default dailyExercisesSlice.reducer;
