import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { IPrivateRoute } from "./privateRoute.interface";
import { TokenService } from "../../utils/restClient/tokenService";
import { useAppSelector } from "../../hooks";
import { RoutesEnum, availablePatientRoutes, userRoles } from "../../shared";

export const PrivateRoute: FC<IPrivateRoute> = ({ isAuth, children }) => {
  const { pathname } = useLocation();
  const currentUser = useAppSelector(
    ({ userReducer }) => userReducer.currentUser
  );

  if (!isAuth) {
    TokenService.deleteTokens();
    return <Navigate to={RoutesEnum.initialRoute} />;
  }

  const isPatientAndRouteAvailable =
    currentUser?.role === userRoles.patient &&
    availablePatientRoutes.find((route) => pathname.includes(route));

  if (isPatientAndRouteAvailable || currentUser?.role !== userRoles.patient) {
    return children;
  }

  return <Navigate to={`${RoutesEnum.admin}${RoutesEnum.profile}`} />;
};
