import { createApi } from "@reduxjs/toolkit/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  ILoginDto,
  ILoginResponse,
  IRegisterDto,
  credential,
} from "../../shared";
import { userApi } from "./userApi";
import { exerciseApi } from "./exerciseApi";
import { dailyExerciseApi } from "./dailyExerciseApi";
import { visitApi } from "./visitApi";
import { scheduleApi } from "./scheduleApi";
import { serviceApi } from "./serviceApi";

export const AUTH_API_REDUCER_KEY = "reducerAuthApi";

const resetApis = (dispatch: Function) => {
  const apis = [
    authApi,
    userApi,
    exerciseApi,
    dailyExerciseApi,
    visitApi,
    scheduleApi,
    serviceApi,
  ];
  apis.forEach((api) => dispatch(api?.util?.resetApiState()));
};

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [AUTH_API_REDUCER_KEY],
  endpoints: (build) => ({
    register: build.mutation<void, IRegisterDto>({
      query: (params) => ({
        url: PathBuilder.authApi.register(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [AUTH_API_REDUCER_KEY],
    }),
    login: build.mutation<ILoginResponse, ILoginDto>({
      query: (params) => ({
        url: PathBuilder.authApi.login(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [AUTH_API_REDUCER_KEY],
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: PathBuilder.authApi.logout(),
        method: RequestTypes.post,
      }),

      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          resetApis(dispatch);
        } catch (err) {
          console.log(`logout error: ${(err as Error).message}`);
        }
      },
      invalidatesTags: [AUTH_API_REDUCER_KEY],
    }),
  }),
});
