import React, { FC, useEffect, useState } from "react";
import { AdminSidebar } from "../AdminSidebar";
import { AdminPanelHeader } from "../AdminPanelHeader";
import { UserBadge } from "../../UserBadge";
import styles from "./adminPanelLayout.module.scss";
import { IAdminPanelLayout } from "./adminPanelLayout.interface";
import { Outlet } from "react-router-dom";
import { adminMenuData, patientMenuData, userRoles } from "../../../shared";
import { IAdminMenu } from "../AdminMenuItem/adminMenuItem.interface";

export const AdminPanelLayout: FC<IAdminPanelLayout> = ({
  handleLogoutRequest,
  currentUser,
}) => {
  const [sidebarMenuData, setSidebarMenuData] = useState<IAdminMenu[]>([]);
  const [isSidebarClose, setIsSidebarClose] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.role === userRoles.patient) {
      setSidebarMenuData(patientMenuData);
    } else {
      setSidebarMenuData(adminMenuData);
    }
  }, [currentUser]);
  return (
    <div className={`${styles.admin_panel_container}`}>
      <AdminSidebar
        sidebarMenuData={sidebarMenuData}
        isSidebarClose={isSidebarClose}
      />
      <div
        className={`${styles.main_part} ${
          isSidebarClose ? styles.closeSidebar : styles.openSidebar
        }`}
      >
        <div className={`${styles.header_wrap}`}>
          <AdminPanelHeader
            setIsSidebarClose={setIsSidebarClose}
            isSidebarClose={isSidebarClose}
          />
          <UserBadge handleLogoutRequest={handleLogoutRequest} />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
