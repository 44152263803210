import React, { FC } from "react";
import styles from "./buttons.module.scss";
import { IButton } from "./button.interface";

export const Button: FC<IButton> = ({
  onClick,
  label,
  disabled = false,
  className,
  customStyle,
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`${styles[className]} ${
        disabled && styles.disabled
      } ${customStyle} button`}
      onClick={(e) => onClick && onClick(e)}
    >
      {label}
    </button>
  );
};
