import Cookies from "js-cookie";
import {
  AuthTokensDataKeysEnum,
  IUpdateTokens,
} from "./tokenService.interfaces";

export const TokenService = {
  updateTokens: (data: IUpdateTokens): void => {
    const { accessToken, refreshToken } = data;
    Cookies.set(AuthTokensDataKeysEnum.accessToken, accessToken);
    Cookies.set(AuthTokensDataKeysEnum.refreshToken, refreshToken);
  },
  deleteTokens: (): void => {
    Cookies.remove(AuthTokensDataKeysEnum.accessToken);
    // Cookies.remove(AuthTokensDataKeysEnum.refreshToken);
  },
  getAccessToken: (): string | null | undefined =>
    Cookies.get(AuthTokensDataKeysEnum.accessToken),

  getRefreshToken: (): string | null | undefined =>
    Cookies.get(AuthTokensDataKeysEnum.refreshToken),
};
