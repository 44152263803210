import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import { DOTS, usePagination } from "../../hooks/usePagination";
import "./pagination.scss";

export interface IPagination {
  onPageChange: (arg: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
}

export const Pagination: FC<IPagination> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
  const [lastPage, setLastPage] = useState(0);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  }) as any[];

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  useEffect(() => {
    if (paginationRange?.length) {
      setLastPage(paginationRange[paginationRange.length - 1]);
    }
  }, [paginationRange]);

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  return (
    <ul
      className={classnames("pagination-container", {
        [className as string]: className,
      })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange
        ? paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <li key={pageNumber + index} className="pagination-item dots">
                  &#8230;
                </li>
              );
            }

            return (
              <li
                key={pageNumber + index}
                className={classnames("pagination-item", {
                  selected: pageNumber === currentPage,
                })}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })
        : null}

      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};
