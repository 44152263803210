import React from "react";
import styles from "./PageLoader.module.scss";
import { Loader } from "../../components/Loader";

export interface PageLoaderProps {
  className?: string;
}
export const PageLoader = ({ className }: PageLoaderProps) => {
  return (
    <div className={styles.PageLoader}>
      <Loader />
    </div>
  );
};
