// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editButton_edit_button__KSBA\\+ {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 15px;
  transition: all 0.2s ease-in-out;
  width: 20px;
  height: 20px;
}
.editButton_edit_button__KSBA\\+ img {
  width: 100%;
}
.editButton_edit_button__KSBA\\+:hover {
  transform: scale(0.93);
  transition: all 0.2s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/components/Buttons/EditButton/editButton.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;EACA,gCAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,sBAAA;EACA,gCAAA;AAAJ","sourcesContent":[".edit_button {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  margin: 0 15px;\n  transition: all 0.2s ease-in-out;\n  width: 20px;\n  height: 20px;\n\n  img {\n    width: 100%;\n  }\n\n  &:hover {\n    transform: scale(0.93);\n    transition: all 0.2s ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit_button": `editButton_edit_button__KSBA+`
};
export default ___CSS_LOADER_EXPORT___;
