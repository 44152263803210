export const exerciseApi = {
  createExercisesTable: (): string =>
    `/api/portal/staff/medical/doctor/exercises/exercise-table`,
  fetchOwnExercisesTable: (): string =>
    `/api/portal/patient/exercises/exercise-table`,
  fetchExercisesTable: (id: string): string =>
    `/api/portal/staff/patients/${id}/exercise-table`,
  updateExercisesTable: (id: string): string =>
    `/api/portal/staff/medical/doctor/exercises/exercise-table/${id}`,
  deleteExercisesTable: (id: string): string =>
    `/api/portal/staff/medical/doctor/exercises/exercise-table/${id}`,
};
