import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ReducerKeys } from "../types";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import { IContactUsDto, IMessageResponse, credential } from "../../shared";

export const contactUsApi = createApi({
  reducerPath: ReducerKeys.CONTACT_US_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.CONTACT_US_API_REDUCER_KEY],
  endpoints: (build) => ({
    sendMessage: build.mutation<IMessageResponse, IContactUsDto>({
      query: (params) => ({
        url: PathBuilder.contactUsApi.sendMessage(),
        method: RequestTypes.post,
        data: params,
      }),
    }),
  }),
});
