import moment from "moment";
import { IVisitResponse } from "../types";

export const getEventsPerHour = (
  hour?: Date,
  eventsPerDay?: IVisitResponse[]
) => {
  const startOfDay = moment(hour).startOf("hour");
  const endOfDay = moment(hour).endOf("hour");
  return eventsPerDay?.filter((event) =>
    moment(event.startTime).isBetween(startOfDay, endOfDay, null, "[)")
  );
};
