import { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useAppDispatch } from "./store";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { showError, showSuccessAlert } from "../store/reducers";
import { IMessageResponse } from "../shared";

type IHandleDeactivate<ApiName extends string> = MutationTrigger<
  MutationDefinition<
    string,
    BaseQueryFn<
      {
        url: string;
        method: string | undefined;
        data?: any;
        params?: any;
      },
      unknown,
      unknown
    >,
    ApiName,
    IMessageResponse,
    ApiName
  >
>;
export const useHandleDeactivate = <ApiName extends string>(
  handleDeactivate: IHandleDeactivate<ApiName>
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isDeactivateConfirmOpen, setIsDeactivateConfirmOpen] = useState(false);
  const [id, setId] = useState<string>("");

  const handleConfirmDeactivate = async () => {
    try {
      await handleDeactivate(id).unwrap();
      setIsDeactivateConfirmOpen(false);
      dispatch(showSuccessAlert(t("alert.successfulDeactivation")));
    } catch (error: any) {
      dispatch(showError(t("alert.somethingWentWrong")));
    }
  };

  const handleCancelDeactivate = () => {
    setIsDeactivateConfirmOpen(false);
  };

  const onDeactivate = (id: string) => {
    setId(id);
    setIsDeactivateConfirmOpen(true);
  };

  return {
    isDeactivateConfirmOpen,
    handleConfirmDeactivate,
    handleCancelDeactivate,
    onDeactivate,
  };
};
