import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  IMedicalCardQualification,
  IMessageResponse,
  credential,
} from "../../shared";

export const MEDICAL_CARD_API_REDUCER_KEY = "medicalCardApi";

export const medicalCardApi = createApi({
  reducerPath: MEDICAL_CARD_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [MEDICAL_CARD_API_REDUCER_KEY],
  endpoints: (build) => ({
    createMedicalCard: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.medicalCardApi.createMedicalCard(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [MEDICAL_CARD_API_REDUCER_KEY],
    }),
    updateMedicalCard: build.mutation<
      IMedicalCardQualification,
      { params: FormData; _id: string }
    >({
      query: (data) => {
        const { params, _id } = data;

        return {
          url: PathBuilder.medicalCardApi.updateMedicalCard(_id),
          method: RequestTypes.put,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: params,
        };
      },
      invalidatesTags: [MEDICAL_CARD_API_REDUCER_KEY],
    }),
    deleteMedicalCard: build.mutation<boolean, string>({
      query: (id) => ({
        url: PathBuilder.medicalCardApi.deleteMedicalCard(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [MEDICAL_CARD_API_REDUCER_KEY],
    }),
    fetchOwnMedicalCard: build.query<IMedicalCardQualification, void>({
      query: () => ({
        url: PathBuilder.medicalCardApi.fetchOwnMedicalCard(),
        method: RequestTypes.get,
      }),
      providesTags: () => [MEDICAL_CARD_API_REDUCER_KEY],
    }),
    fetchUserMedicalCard: build.query<IMedicalCardQualification, string>({
      query: (userId) => ({
        url: PathBuilder.medicalCardApi.fetchUserMedicalCard(userId),
        method: RequestTypes.get,
      }),
      providesTags: () => [MEDICAL_CARD_API_REDUCER_KEY],
    }),
  }),
});
