import { ArticleTypes } from "../articleTypes";
import { SortingFields } from "./sortingFields";
import { SortingOrders } from "./sortingOrders";

export const articleListQueryData = {
  page: 1,
  limit: 15,
  type: ArticleTypes.NEWS,
  sortField: SortingFields.title,
  sortOrder: SortingOrders.ASC,
};
