import React, { useEffect } from "react";
import styles from "./alertNotification.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideError, hideSuccessAlert } from "../../store/reducers";

export const AlertNotification = () => {
  const { errorMessage, isShowError, isShowSuccess, successMessage } =
    useAppSelector(({ alertReducer }) => alertReducer);

  const dispatch = useAppDispatch();

  const handleCloseAlert = () => {
    if (isShowError) {
      dispatch(hideError());
    }
    if (isShowSuccess) {
      dispatch(hideSuccessAlert());
    }
  };

  useEffect(() => {
    if (isShowError) {
      setTimeout(() => dispatch(hideError()), 3000);
    }
    if (isShowSuccess) {
      setTimeout(() => dispatch(hideSuccessAlert()), 3000);
    }
  }, [isShowError, isShowSuccess]);

  return (
    <>
      <div
        className={`${styles.alert_container} ${
          (isShowError || isShowSuccess) && styles.show_alert
        } ${isShowError && styles.error_alert}`}
      >
        <div className={styles.message_wrap}>
          <span className={styles.alert_message}>
            {successMessage && successMessage}
            {errorMessage && errorMessage}
          </span>
        </div>
        <div className={styles.icon_wrap}>
          <span className={styles.close_icon} onClick={handleCloseAlert}>
            &times;
          </span>
        </div>
      </div>
    </>
  );
};
