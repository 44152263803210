import { ReducerKeys } from "../../store/types";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  IPlace,
  IPlaceDto,
  IPlaceQuery,
  IPlaceResponse,
  IMessageResponse,
  credential,
} from "../../shared";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";

export const placeApi = createApi({
  reducerPath: ReducerKeys.PLACES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.PLACES_API_REDUCER_KEY],
  endpoints: (build) => ({
    createPlace: build.mutation<IMessageResponse, IPlaceDto>({
      query: (params) => ({
        url: PathBuilder.placeApi.createPlace(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.PLACES_API_REDUCER_KEY],
    }),
    updatePlace: build.mutation<IPlace, IPlace>({
      query: (params) => {
        const { _id, ...rest } = params;
        return {
          url: PathBuilder.placeApi.updatePlace(_id as string),
          method: RequestTypes.patch,
          data: rest,
        };
      },
      invalidatesTags: [ReducerKeys.PLACES_API_REDUCER_KEY],
    }),
    fetchPlaceList: build.query<IPlaceResponse, IPlaceQuery>({
      query: (params) => ({
        url: PathBuilder.placeApi.fetchPlaceList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.PLACES_API_REDUCER_KEY],
    }),
    fetchPlace: build.query<IPlace, string>({
      query: (id) => ({
        url: PathBuilder.placeApi.fetchPlace(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.PLACES_API_REDUCER_KEY],
    }),
    activatePlace: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.placeApi.activatePlace(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.PLACES_API_REDUCER_KEY],
    }),
    deactivatePlace: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.placeApi.deactivatePlace(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.PLACES_API_REDUCER_KEY],
    }),
  }),
});
