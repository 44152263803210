import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import styles from "./customLink.module.scss";
import { ICustomLink } from "./customLink.interface";

export const CustomLink = ({
  children,
  to,
  className,
  customStyle,
  disabled,
  ...props
}: ICustomLink) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div
      className={`${styles[className as string]} ${
        disabled && styles.disabled
      } ${customStyle} link`}
    >
      <Link className={match && styles.active_link} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
};
