type Token = string;

export interface IUpdateTokens {
  accessToken: Token;
  refreshToken: Token;
}

export enum AuthTokensDataKeysEnum {
  accessToken = "jat-med-access-token",
  refreshToken = "jat-med-refresh-token",
  rememberMe = "remember-me",
}
