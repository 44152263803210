// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableDeleteButton_table_delete_button__OB9F\\+ {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 3px;
  transition: all 0.2s ease-in-out;
  width: 20px;
  margin: 0 15px;
  margin-left: auto;
}
.tableDeleteButton_table_delete_button__OB9F\\+ img {
  width: 100%;
}
.tableDeleteButton_table_delete_button__OB9F\\+:hover {
  transform: scale(0.93);
  transition: all 0.2s ease-in-out;
}
.tableDeleteButton_table_delete_button__OB9F\\+:active {
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}
@media (max-width: 991px) {
  .tableDeleteButton_table_delete_button__OB9F\\+ {
    width: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/Buttons/TableDeleteButton/tableDeleteButton.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,gCAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,sBAAA;EACA,gCAAA;AAAJ;AAGE;EACE,qBAAA;EACA,gCAAA;AADJ;AAIE;EAxBF;IAyBI,WAAA;EADF;AACF","sourcesContent":[".table_delete_button {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 3px;\n  transition: all 0.2s ease-in-out;\n  width: 20px;\n  margin: 0 15px;\n  margin-left: auto;\n\n  img {\n    width: 100%;\n  }\n\n  &:hover {\n    transform: scale(0.93);\n    transition: all 0.2s ease-in-out;\n  }\n\n  &:active {\n    transform: scale(0.9);\n    transition: all 0.2s ease-in-out;\n  }\n\n  @media (max-width: 991px) {\n    width: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_delete_button": `tableDeleteButton_table_delete_button__OB9F+`
};
export default ___CSS_LOADER_EXPORT___;
