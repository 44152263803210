import { ReducerKeys } from "../../store/types";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import { ICreateScheduleDto, credential } from "../../shared";
import {
  IMessageResponse,
  ISchedule,
  IScheduleQuery,
} from "../../shared/types";

export const scheduleApi = createApi({
  reducerPath: ReducerKeys.SCHEDULE_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
  endpoints: (build) => ({
    createSchedule: build.mutation<IMessageResponse, ICreateScheduleDto>({
      query: (params) => ({
        url: PathBuilder.scheduleApi.createScheduleByAdmin(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
    updateSchedule: build.mutation<ISchedule, ISchedule>({
      query: (params) => {
        const { _id, ...newParams } = params;
        return {
          url: PathBuilder.scheduleApi.updateScheduleByAdmin(_id as string),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
    deleteSchedule: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.scheduleApi.deleteScheduleByAdmin(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
    fetchUserSchedule: build.query<ISchedule[], IScheduleQuery>({
      query: (params) => ({
        url: PathBuilder.scheduleApi.fetchUserSchedule(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
    fetchSchedule: build.query<ISchedule, string>({
      query: (id) => ({
        url: PathBuilder.scheduleApi.fetchScheduleByAdmin(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
    createScheduleByStaff: build.mutation<IMessageResponse, ICreateScheduleDto>(
      {
        query: (params) => ({
          url: PathBuilder.scheduleApi.createScheduleByStaff(),
          method: RequestTypes.post,
          data: params,
        }),
        invalidatesTags: [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
      }
    ),
    fetchScheduleByStaff: build.query<ISchedule, string>({
      query: (id) => ({
        url: PathBuilder.scheduleApi.fetchScheduleByStaff(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
    updateScheduleByStaff: build.mutation<IMessageResponse, ISchedule>({
      query: (params) => {
        const { _id, ...newParams } = params;
        return {
          url: PathBuilder.scheduleApi.updateScheduleByStaff(_id as string),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [ReducerKeys.SCHEDULE_API_REDUCER_KEY],
    }),
  }),
});
