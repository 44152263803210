export const medicalCardApi = {
  createMedicalCard: (): string =>
    `/api/portal/staff/medical/doctor/patient-qualifications`,
  updateMedicalCard: (medicalCardId: string): string =>
    `/api/portal/staff/medical/doctor/patient-qualifications/${medicalCardId}`,
  deleteMedicalCard: (medicalCardId: string): string =>
    `/api/portal/staff/medical/doctor/patient-qualifications/${medicalCardId}`,
  fetchOwnMedicalCard: (): string =>
    `/api/portal/patient/patient-qualifications`,
  fetchUserMedicalCard: (userId: string): string =>
    `/api/portal/staff/patients/${userId}/patient-qualifications`,
};
