import React from "react";
import { CustomLink } from "../CustomLink";
import styles from "./navMenu.module.scss";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../shared";

export const NavMenu = () => {
  const { t } = useTranslation();

  return (
    <nav className={styles.nav_menu}>
      <ul>
        <li>
          <CustomLink to={RoutesEnum.initialRoute}>
            {t("navigation.home")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to={RoutesEnum.about}>
            {t("navigation.aboutUs")}
          </CustomLink>
        </li>
        {/* <li>
          <CustomLink to="/service">{t("navigation.services")}</CustomLink>
        </li> */}
        <li>
          <CustomLink to={RoutesEnum.articles}>
            {t("navigation.info")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to={RoutesEnum.contacts}>
            {t("navigation.contacts")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to={RoutesEnum.reviews}>
            {t("navigation.reviews")}
          </CustomLink>
        </li>
      </ul>
    </nav>
  );
};
