import React from "react";

import "./Loader.scss";

export interface LoaderProps {
  className?: string;
}
export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={"lds-ellipsis"}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
