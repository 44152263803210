import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  IService,
  IServiceQuery,
  IServiceResponse,
  IMessageResponse,
  credential,
} from "../../shared";
import { ReducerKeys } from "../types";

export const serviceApi = createApi({
  reducerPath: ReducerKeys.SERVICES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.SERVICES_API_REDUCER_KEY],
  endpoints: (build) => ({
    createService: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.serviceApi.createService(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.SERVICES_API_REDUCER_KEY],
    }),
    updateService: build.mutation<IService, { params: FormData; _id: string }>({
      query: (data) => {
        const { params, _id } = data;

        return {
          url: PathBuilder.serviceApi.updateService(_id),
          method: RequestTypes.put,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: params,
        };
      },
      invalidatesTags: [ReducerKeys.SERVICES_API_REDUCER_KEY],
    }),
    fetchServiceList: build.query<IServiceResponse, IServiceQuery>({
      query: (params) => ({
        url: PathBuilder.serviceApi.fetchServiceList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.SERVICES_API_REDUCER_KEY],
    }),
    fetchService: build.query<IService, string>({
      query: (id) => ({
        url: PathBuilder.serviceApi.fetchService(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.SERVICES_API_REDUCER_KEY],
    }),
    activateService: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.serviceApi.activateService(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.SERVICES_API_REDUCER_KEY],
    }),
    deactivateService: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.serviceApi.deactivateService(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.SERVICES_API_REDUCER_KEY],
    }),
  }),
});
