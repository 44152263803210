// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_Modal__20DNd {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.modal_overlay__juLsQ {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content__UrqlL {
  display: flex;
  position: relative;
  padding: 20px;
  border-radius: 12px;
  transition: 0.3s transform;
  transform: scale(0.5);
  width: 100%;
  max-width: 1620px;
}
.modal_content__UrqlL .modal_close_button__tNRB3 {
  font-size: 35px;
  line-height: 1;
  background: none;
  border: none;
  color: #ccd0d9;
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
}
.modal_content__UrqlL .modal_close_button__tNRB3:hover {
  color: #a3a4a8;
}

.modal_opened__LHPbj {
  pointer-events: auto;
  opacity: 1;
  z-index: 100;
}
.modal_opened__LHPbj .modal_content__UrqlL {
  transform: scale(1);
  transition: 0.3s transform;
}

.modal_isClosing__oDBDJ .modal_content__UrqlL {
  transform: scale(0.2);
  transition: 0.3s transform;
}`, "",{"version":3,"sources":["webpack://./src/app/components/Modal/modal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,UAAA;EACA,oBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,qBAAA;EACA,WAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AACJ;AACI;EACE,cAAA;AACN;;AAIA;EACE,oBAAA;EACA,UAAA;EACA,YAAA;AADF;AAGE;EACE,mBAAA;EACA,0BAAA;AADJ;;AAME;EACE,qBAAA;EACA,0BAAA;AAHJ","sourcesContent":[".Modal {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  opacity: 0;\n  pointer-events: none;\n  z-index: -1;\n}\n\n.overlay {\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  display: flex;\n  position: relative;\n  padding: 20px;\n  border-radius: 12px;\n  transition: 0.3s transform;\n  transform: scale(0.5);\n  width: 100%;\n  max-width: 1620px;\n\n  .close_button {\n    font-size: 35px;\n    line-height: 1;\n    background: none;\n    border: none;\n    color: #ccd0d9;\n    position: absolute;\n    top: 20px;\n    right: 25px;\n    cursor: pointer;\n\n    &:hover {\n      color: #a3a4a8;\n    }\n  }\n}\n\n.opened {\n  pointer-events: auto;\n  opacity: 1;\n  z-index: 100;\n\n  .content {\n    transform: scale(1);\n    transition: 0.3s transform;\n  }\n}\n\n.isClosing {\n  .content {\n    transform: scale(0.2);\n    transition: 0.3s transform;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Modal": `modal_Modal__20DNd`,
	"overlay": `modal_overlay__juLsQ`,
	"content": `modal_content__UrqlL`,
	"close_button": `modal_close_button__tNRB3`,
	"opened": `modal_opened__LHPbj`,
	"isClosing": `modal_isClosing__oDBDJ`
};
export default ___CSS_LOADER_EXPORT___;
