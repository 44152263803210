export const userRoles = {
  admin: "admin",
  doctor: "doctor",
  patient: "patient",
  rehabilitator: "rehabilitator",
};

export const userRolesLocales = [
  { value: "admin", title: "userRolesLocales.admin" },
  { value: "doctor", title: "userRolesLocales.doctor" },
  { value: "patient", title: "userRolesLocales.patient" },
  { value: "rehabilitator", title: "userRolesLocales.rehabilitator" },
];

export const communicationLanguages = [
  { value: "pl", title: "Polski" },
  { value: "ua", title: "Українська" },
  { value: "en", title: "English" },
];
