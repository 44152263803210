import { useMemo } from "react";
import {
  ISchedule,
  IService,
  IVisitResponse,
  getAllBookedTime,
  getBookedVisitTime,
} from "../shared";

type WorkerType = {
  _id: string;
  firstName: string;
  lastName: string;
  startedAt: string;
  finishedAt: string;
};

export type VisitMapType = {
  [workerId: string]: {
    worker: WorkerType;
    bookedVisitTime: Date[];
  };
};

interface IUseVisitListByServiceAndWorkerMap {
  visits?: IVisitResponse[];
  workerListByScheduleAndService?: ISchedule[];
  chosenService?: IService;
}

export const useVisitListByServiceAndWorkerMap = ({
  visits,
  workerListByScheduleAndService,
  chosenService,
}: IUseVisitListByServiceAndWorkerMap) => {
  const visitListByServiceAndWorkerMap = useMemo(() => {
    // Precompute visits map for quick access
    const visitsMap = visits?.reduce((acc, visit) => {
      if (!acc[visit.doctorId]) {
        acc[visit.doctorId] = [];
      }
      acc[visit.doctorId].push(visit);
      return acc;
    }, {} as Record<string, IVisitResponse[]>);

    const visitMap = workerListByScheduleAndService?.reduce((acc, worker) => {
      const workerId = worker.user?._id;
      const workerVisits = visitsMap?.[workerId as string] || [];
      const bookedVisitTime = getBookedVisitTime(
        workerVisits,
        chosenService as IService
      );

      acc[workerId as string] = {
        worker: {
          _id: workerId as string,
          firstName: worker.user?.firstName as string,
          lastName: worker.user?.lastName as string,
          startedAt: worker.startAt as string,
          finishedAt: worker.finishAt as string,
        },
        bookedVisitTime,
      };

      return acc;
    }, {} as VisitMapType);

    return visitMap;
  }, [visits, workerListByScheduleAndService, chosenService]);

  const generalBookedTime = getAllBookedTime(visitListByServiceAndWorkerMap);

  return { visitList: visitListByServiceAndWorkerMap, generalBookedTime };
};
