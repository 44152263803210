import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IScrollToTop {
  setPathname: (arg: string) => void;
}

export default function ScrollToTop({ setPathname }: IScrollToTop) {
  const { pathname } = useLocation();

  useEffect(() => {
    setPathname(pathname);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}
