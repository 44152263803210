import { edit_pencil } from "@jat-med/assets";
import React, { FC } from "react";
import styles from "./editButton.module.scss";
import { IEditButton } from "./editButton.interface";

export const EditButton: FC<IEditButton> = ({ onClick }) => {
  return (
    <button className={styles.edit_button} onClick={onClick}>
      <img src={edit_pencil} alt="edit_btn" />
    </button>
  );
};
