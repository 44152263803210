export const scheduleApi = {
  createScheduleByAdmin: (): string => `/api/admin/schedule`,
  updateScheduleByAdmin: (scheduleId: string): string =>
    `/api/admin/schedule/${scheduleId}`,
  deleteScheduleByAdmin: (scheduleId: string): string =>
    `/api/admin/schedule/${scheduleId}`,
  fetchScheduleByAdmin: (scheduleId: string): string =>
    `/api/admin/schedule/${scheduleId}`,
  fetchUserSchedule: (): string => `/api/public/schedules`,
  createScheduleByStaff: (): string => `/api/portal/staff/medical/schedule`,
  fetchScheduleByStaff: (id: string): string =>
    `/api/portal/staff/medical/schedule/${id}`,
  updateScheduleByStaff: (id: string): string =>
    `/api/portal/staff/medical/schedule/${id}`,
};
