export enum RequestTypes {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
  patch = "PATCH",
}

export enum WatchedRoutesEnum {
  registration = "api/auth/registration",
  logout = "api/auth/logout",
  login = "api/auth/login",
  refresh = "/api/auth/refresh",
  createReview = "/api/reviews",
  updateCurrentUser = "/api/users/me",
}
