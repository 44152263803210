import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  IMessageResponse,
  IUser,
  IUserQuery,
  IUserResponse,
  credential,
} from "../../shared";
import { setCurrentUser } from "../reducers/userSlice";
import { ReducerKeys } from "../types";

export const userApi = createApi({
  reducerPath: ReducerKeys.USERS_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.USERS_API_REDUCER_KEY],

  endpoints: (build) => ({
    createUser: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.userApi.createUser(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    updateUser: build.mutation<IUser, { data: FormData; id: string }>({
      query: (params) => {
        return {
          url: PathBuilder.userApi.updateUser(params.id),
          method: RequestTypes.put,
          data: params.data,
        };
      },

      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    deleteUser: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.userApi.deactivateUser(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    deactivateUser: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.userApi.deactivateUser(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    activateUser: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.userApi.activateUser(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    fetchWorkerList: build.query<IUserResponse, IUserQuery>({
      query: (params) => ({
        url: PathBuilder.userApi.fetchWorkerList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    fetchWorker: build.query<IUser, string>({
      query: (id) => ({
        url: PathBuilder.userApi.fetchWorker(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    fetchPatientList: build.query<IUserResponse, IUserQuery>({
      query: (params) => ({
        url: PathBuilder.userApi.fetchPatientList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    fetchPatient: build.query<IUser, string>({
      query: (id) => ({
        url: PathBuilder.userApi.fetchPatient(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    createPatient: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.userApi.createPatient(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    updatePatient: build.mutation<IUser, { data: FormData; id: string }>({
      query: (params) => ({
        url: PathBuilder.userApi.updatePatient(params.id),
        method: RequestTypes.put,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params.data,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
    fetchCurrentUser: build.query<IUser, void>({
      query: () => ({
        url: PathBuilder.userApi.fetchCurrentUser(),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.USERS_API_REDUCER_KEY],
      onCacheEntryAdded: async (arg, { cacheDataLoaded, dispatch }) => {
        const { data } = await cacheDataLoaded;
        dispatch(setCurrentUser(data));
      },
    }),
    updateCurrentUser: build.mutation<IUser, FormData>({
      query: (params) => ({
        url: PathBuilder.userApi.updateCurrentUser(),
        method: RequestTypes.put,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.USERS_API_REDUCER_KEY],
    }),
  }),
});
