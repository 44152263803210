export const articleApi = {
  createArticle: (): string => `/api/admin/articles`,
  updateArticle: (articleId: string): string =>
    `/api/admin/articles/${articleId}`,
  deleteArticle: (articleId: string): string =>
    `/api/admin/articles/${articleId}`,
  fetchArticleList: (): string => `/api/public/articles`,
  fetchArticle: (articleId: string): string =>
    `/api/public/articles/${articleId}`,
};
