import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import uk from "./locales/uk.json";
import pl from "./locales/pl.json";
import en from "./locales/en.json";
import moment from "moment";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  uk: { translation: uk },
  pl: { translation: pl },
  en: { translation: en },
};

export const changeLocale = (locale: string): void => {
  if (locale) {
    i18n.changeLanguage(locale);
    moment.locale(locale);
  }
};

export const initI18n = (): Promise<TFunction> =>
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    fallbackLng: "pl",
  });
