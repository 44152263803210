import { ReducerKeys } from "../../store/types";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  ICreateVisitDto,
  IMessageResponse,
  IUpdateVisitDto,
  IVisitListResponse,
  IVisitQuery,
  IVisitResponse,
  credential,
} from "../../shared";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";

export const visitApi = createApi({
  reducerPath: ReducerKeys.VISITS_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.VISITS_API_REDUCER_KEY],
  endpoints: (build) => ({
    createVisit: build.mutation<IMessageResponse, ICreateVisitDto>({
      query: (params) => ({
        url: PathBuilder.visitApi.createVisit(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    fetchVisit: build.query<IVisitResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.fetchVisit(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    fetchVisitList: build.query<IVisitListResponse, IVisitQuery>({
      query: (params) => ({
        url: PathBuilder.visitApi.fetchVisitList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    fetchPublicVisitList: build.query<IVisitListResponse, IVisitQuery>({
      query: (params) => ({
        url: PathBuilder.visitApi.fetchPublicVisitList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    updateVisit: build.mutation<IVisitResponse, IUpdateVisitDto>({
      query: (params) => {
        const { _id, ...newParams } = params;

        return {
          url: PathBuilder.visitApi.updateVisit(_id),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    deleteVisit: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.deleteVisit(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    cancelVisit: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.cancelVisit(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    completeVisit: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.completeVisit(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    fetchPatientVisitList: build.query<IVisitListResponse, IVisitQuery>({
      query: (params) => ({
        url: PathBuilder.visitApi.fetchPatientVisitList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    fetchPatientVisit: build.query<IVisitResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.fetchPatientVisit(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    cancelPatientVisit: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.cancelPatientVisit(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
    completePatientVisit: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.visitApi.completePatientVisit(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.VISITS_API_REDUCER_KEY],
    }),
  }),
});
