// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminPanelLayout_admin_panel_container__kBUMW {
  display: flex;
  background-color: #f6f7fb;
  overflow: hidden;
}
.adminPanelLayout_admin_panel_container__kBUMW .adminPanelLayout_main_part__KbROz {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.adminPanelLayout_admin_panel_container__kBUMW .adminPanelLayout_main_part__KbROz .adminPanelLayout_header_wrap__1H6qA {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 10px;
  border-bottom: 1px solid #ccd0d9;
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/AdminPanel/AdminPanelLayout/adminPanelLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,kBAAA;AACJ;AACI;EACE,aAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;EACA,gCAAA;EACA,yBAAA;AACN","sourcesContent":[".admin_panel_container {\n  display: flex;\n  background-color: #f6f7fb;\n  overflow: hidden;\n\n  .main_part {\n    width: 100%;\n    height: 100vh;\n    overflow-x: hidden;\n\n    .header_wrap {\n      display: flex;\n      position: sticky;\n      top: 0;\n      z-index: 10;\n      padding: 10px;\n      border-bottom: 1px solid #ccd0d9;\n      background-color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_panel_container": `adminPanelLayout_admin_panel_container__kBUMW`,
	"main_part": `adminPanelLayout_main_part__KbROz`,
	"header_wrap": `adminPanelLayout_header_wrap__1H6qA`
};
export default ___CSS_LOADER_EXPORT___;
