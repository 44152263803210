// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languagMenu_language_menu__di33n {
  display: flex;
  align-items: center;
  gap: 5px;
}
.languagMenu_language_menu__di33n .languagMenu_active__5rp\\+7 {
  background-color: #ff6138;
  border: #ff6138;
  color: white;
}
@media (max-width: 1199px) {
  .languagMenu_language_menu__di33n button {
    font-size: 11px;
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 991px) {
  .languagMenu_language_menu__di33n button {
    font-size: 10px;
    width: 25px;
    height: 25px;
    padding: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/LanguageMenu/languagMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,yBAAA;EACA,eAAA;EACA,YAAA;AACJ;AAEE;EACE;IACE,eAAA;IACA,WAAA;IACA,YAAA;EAAJ;AACF;AAGE;EACE;IACE,eAAA;IACA,WAAA;IACA,YAAA;IACA,UAAA;EADJ;AACF","sourcesContent":[".language_menu {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n\n  .active {\n    background-color: #ff6138;\n    border: #ff6138;\n    color: white;\n  }\n\n  @media (max-width: 1199px) {\n    button {\n      font-size: 11px;\n      width: 30px;\n      height: 30px;\n    }\n  }\n\n  @media (max-width: 991px) {\n    button {\n      font-size: 10px;\n      width: 25px;\n      height: 25px;\n      padding: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language_menu": `languagMenu_language_menu__di33n`,
	"active": `languagMenu_active__5rp+7`
};
export default ___CSS_LOADER_EXPORT___;
