import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { AxiosError, AxiosRequestConfig } from "axios";
import { restClient } from "./restClient";

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async (restClientOptions) => {
    try {
      const result = await restClient(restClientOptions);
      return result;
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
