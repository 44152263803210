import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  IMessageResponse,
  IReview,
  IReviewQuery,
  IReviewResponse,
  IReviewUpdateRequest,
  credential,
} from "../../shared";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import { ReducerKeys } from "../types";

export const reviewApi = createApi({
  reducerPath: ReducerKeys.REVIEWS_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.REVIEWS_API_REDUCER_KEY],
  endpoints: (build) => ({
    createReview: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.reviewApi.createReview(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.REVIEWS_API_REDUCER_KEY],
    }),
    updateReview: build.mutation<IReview, IReviewUpdateRequest>({
      query: (params) => {
        const { _id, ...newParams } = params;

        return {
          url: PathBuilder.reviewApi.updateReview(_id),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [ReducerKeys.REVIEWS_API_REDUCER_KEY],
    }),
    deleteReview: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.reviewApi.deleteReview(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.REVIEWS_API_REDUCER_KEY],
    }),
    fetchReviewList: build.query<IReviewResponse, IReviewQuery>({
      query: (params) => ({
        url: PathBuilder.reviewApi.fetchReviewList(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.REVIEWS_API_REDUCER_KEY],
    }),
    fetchReview: build.query<IReview, string>({
      query: (id) => ({
        url: PathBuilder.reviewApi.fetchReview(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.REVIEWS_API_REDUCER_KEY],
    }),
  }),
});
