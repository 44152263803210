export enum RoutesEnum {
  initialRoute = "/",
  services = "/services",
  serviseDetails = "/services/:serviceId",
  createService = "/services/create",
  updateService = "/services/create/:serviceId",
  articles = "/articles",
  create = "/create",
  createArticles = "/articles/create",
  updateArticles = "/articles/create/:articleId",
  articlesDetails = "/articles/:articleId",
  about = "/about",
  contacts = "/contacts",
  doctors = "/doctors",
  doctorDetails = "/doctors/:doctorId",
  doctorUpdate = "/doctors/:doctorId/update",
  reviews = "/reviews",
  reviewDetails = "/reviews/:reviewId",
  appointment = "/appointment",
  admin = "/admin",
  patients = "/patients",
  patientDetails = "/patients/:patientId",
  patientUpdate = "/patients/:patientId/update",
  exercisesTable = "/exercises-table",
  visitForm = "/visit-form",
  editVisitForm = "/visit-form/:visitId",
  rehabilitators = "/rehabilitators",
  rehabilitatorDetails = "/rehabilitators/:rehabilitatorId",
  rehabilitatorUpdate = "/rehabilitators/:rehabilitatorId/update",
  createUser = "/register-user",
  profile = "/profile",
  dailyVisits = "/daily-visits",
  visitsHistory = "/visits-history",
  visitsCalendar = "/visits-calendar",
  hospitals = "/hospitals",
  createHospital = "/hospitals/create",
  myTable = "/my-exercises",
  medicalCard = "/medical-card",
  schedules = "/schedules",
  uploadPhoto = "/upload-foto",
  gallery = "/gallery",
  newVisit = "/new-visit",
  faq = "/faq",
}

export const availablePatientRoutes = [
  RoutesEnum.profile,
  RoutesEnum.dailyVisits,
  RoutesEnum.visitsHistory,
  RoutesEnum.myTable,
  RoutesEnum.medicalCard,
  RoutesEnum.uploadPhoto,
  RoutesEnum.gallery,
  RoutesEnum.faq,
  RoutesEnum.newVisit,
];
