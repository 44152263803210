//@ts-nocheck
import moment from "moment";
import { IService, IVisitResponse } from "../types";
import { IEventsPerDay } from "../../screens/AdminPanel/Visits/VisitsCalendar/visitsCalendar.interface";

export const getBookedVisitTime = (
  visits: IVisitResponse[] | IEventsPerDay[] | undefined,
  service: IService
) => {
  const visitMap: {
    [key: string]: { number: number; serviceId: string };
  } = {};

  (visits || []).forEach((visit) => {
    if (visitMap[visit.startTime as string])
      visitMap[visit.startTime as string].number += 1;

    if (!visitMap[visit.startTime as string])
      visitMap[visit.startTime as string] = {
        number: 1,

        serviceId: visit.serviceId,
      };
  });

  return Object.keys(visitMap)
    .filter(
      (visitTime) =>
        visitMap[visitTime].number >= service.numberOfVisitsAtTime ||
        visitMap[visitTime].serviceId !== service._id
    )
    .map((visitTime) => moment(visitTime).toDate());
};

export const getAllBookedTime = (visitMap: any): Date[] => {
  if (!visitMap) {
    return [];
  }

  const timeCount = new Map<string, number>();
  // const workerTimes = new Map<string, Date[]>();

  // Collect all booked visit times and their counts
  Object.values(visitMap).forEach(({ bookedVisitTime }, _, arr) => {
    bookedVisitTime.forEach((time) => {
      const timeString = time.toISOString();
      timeCount.set(timeString, (timeCount.get(timeString) || 0) + 1);
    });
  });

  const totalWorkers = Object.keys(visitMap).length;

  // Filter times that meet the criteria
  return Array.from(timeCount).reduce((acc, [timeString, count]) => {
    if (count === totalWorkers || count > 1) {
      acc.push(moment(timeString).toDate());
    } else if (count === 1) {
      // Find the worker who has this time booked and check if they work alone
      const timeObj = moment(timeString).toDate();
      const workerIsAlone = Object.entries(visitMap).some(
        ([workerId, { worker, bookedVisitTime }]) => {
          const workerBookedTimes = bookedVisitTime.map((t) => t.toISOString());
          if (workerBookedTimes.includes(timeString)) {
            const otherWorkers = Object.entries(visitMap).filter(
              ([id]) => id !== workerId
            );
            return otherWorkers.every(([, { worker: otherWorker }]) => {
              const otherStart = new Date(otherWorker.startedAt);
              const otherEnd = new Date(otherWorker.finishedAt);
              return timeObj < otherStart || timeObj >= otherEnd;
            });
          }
          return false;
        }
      );

      if (workerIsAlone) {
        acc.push(timeObj);
      }
    }
    return acc;
  }, []);
};
