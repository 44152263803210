export const exercisesTableStage_2 = [
  {
    _id: "1",
    number: 1,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "2",
    number: 2,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "3",
    number: 3,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "4",
    number: 4,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "5",
    number: 5,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "6",
    number: 6,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "3",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "3",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "7",
    number: 7,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "3",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "3",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "8",
    number: 8,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "2",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "2",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "9",
    number: 9,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "2",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "2",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "10",
    number: 10,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "11",
    number: 11,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "12",
    number: 12,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "13",
    number: 13,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "14",
    number: 14,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "15",
    number: 15,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "5",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "16",
    number: 16,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "17",
    number: 17,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "18",
    number: 18,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "19",
    number: 19,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "20",
    number: 20,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "21",
    number: 21,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "22",
    number: 22,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "23",
    number: 23,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "24",
    number: 24,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "25",
    number: 25,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "26",
    number: 26,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "27",
    number: 27,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "28",
    number: 28,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "29",
    number: 29,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "30",
    number: 30,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
  {
    _id: "31",
    number: 31,
    isCompleted: false,
    date: "",
    bloodPressureBefore: "",
    pulseBefore: "",
    bloodPressureAfter: "",
    pulseAfter: "",
    angle: "15",
    caterpillarLegsBent: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    caterpillarLegsStraight: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    backPressure: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    oneLegSwings: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTest: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swingsTwoLegs: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    booklet: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frog: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bicycle: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    legLift: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    weigh: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    stick: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    cobra: {
      value: "4",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    downhillSkiPelvisUp: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallow: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowWithHead: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowLegsOnly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    swallowHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    frogHARD: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45P: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    runningOnTheBoard45B: {
      value: "",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsStatic: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    wingsDynamic: {
      value: "8",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    butterfly: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
    bat: {
      value: "6",
      additionalWeight: "",
      comment: "",
      like: false,
      dislike: false,
    },
  },
];
