import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAlertState } from "../types";

const initialState: IAlertState = {
  isShowError: false,
  errorMessage: "",
  isShowSuccess: false,
  successMessage: "",
};

export const alertSlice = createSlice({
  name: "uiReducer",
  initialState,
  reducers: {
    showError: (state, action: PayloadAction<string>) => ({
      ...state,
      isShowError: true,
      errorMessage: action.payload,
    }),
    hideError: (state) => ({
      ...state,
      isShowError: false,
      errorMessage: "",
    }),
    showSuccessAlert: (state, action: PayloadAction<string>) => ({
      ...state,
      isShowSuccess: true,
      successMessage: action.payload,
    }),
    hideSuccessAlert: (state) => ({
      ...state,
      isShowSuccess: false,
      successMessage: "",
    }),
  },
});

export const { showError, hideError, hideSuccessAlert, showSuccessAlert } =
  alertSlice.actions;
export default alertSlice.reducer;
