import { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useAppDispatch } from "./store";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { showError, showSuccessAlert } from "../store/reducers";
import { IMessageResponse } from "../shared";

type IHandleDelete<ApiName extends string> = MutationTrigger<
  MutationDefinition<
    string,
    BaseQueryFn<
      {
        url: string;
        method: string | undefined;
        data?: any;
        params?: any;
      },
      unknown,
      unknown
    >,
    ApiName,
    IMessageResponse,
    ApiName
  >
>;
export const useHandleDelete = <ApiName extends string>(
  handleDelete: IHandleDelete<ApiName>
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [id, setId] = useState<string>("");

  const handleConfirmDelete = async () => {
    try {
      await handleDelete(id).unwrap();
      setIsConfirmOpen(false);
      dispatch(showSuccessAlert(t("alert.successfulRemoval")));
    } catch (error: any) {
      dispatch(showError(t("alert.somethingWentWrong")));
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmOpen(false);
  };

  const onDelete = (id: string) => {
    setId(id);
    setIsConfirmOpen(true);
  };

  return {
    isConfirmOpen,
    handleConfirmDelete,
    handleCancelDelete,
    onDelete,
  };
};
