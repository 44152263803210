export const offerCardsData = {
  title: "home.offerCardsData.title",
  isRelatedToExercise: false,
  isActionCard: true,
  _id: "testId",
  description: "string",
  duration: 0,
  price: 0,
  numberOfVisitsAtTime: 0,
  currency: "string",
  articleId: "string",
  iconLink: "string",
  backgroundLink: "string",
};
