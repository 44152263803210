import React, { FC } from "react";
import styles from "./modalContentContainer.module.scss";
import { IModalContentContainer } from "./modalContentContainer.interface";

export const ModalContentContainer: FC<IModalContentContainer> = ({
  text,
  modalTitle,
  children,
  customStyles,
}) => {
  return (
    <div className={`${styles.modal_content} ${customStyles}`}>
      <div className={styles.modal_header}>
        <p className={styles.text}>{text}</p>
        <h2 className={styles.modal_title}>{modalTitle}</h2>
      </div>
      <div className={styles.modal_body}>{children}</div>
    </div>
  );
};
