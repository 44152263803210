import { userRoles } from "../userRoles";
import { SortingFields } from "./sortingFields";
import { SortingOrders } from "./sortingOrders";

export const userListQueryData = {
  page: 1,
  limit: 10,
  roles: [userRoles.doctor],
  sortField: SortingFields.firstName,
  sortOrder: SortingOrders.ASC,
  isActive: true,
};

export const patientQueryData = {
  page: 1,
  limit: 10,
  sortField: SortingFields.firstName,
  sortOrder: SortingOrders.ASC,
  isActive: true,
};
