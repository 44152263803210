import { ReducerKeys } from "./../types/reducerKeys";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  IFile,
  IFilesResponse,
  IMessageResponse,
  IPhotoQuery,
  credential,
} from "../../shared";

export const filesApi = createApi({
  reducerPath: ReducerKeys.FILES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.FILES_API_REDUCER_KEY],
  endpoints: (build) => ({
    postFile: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.filesApi.postFileByStaff(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    fetchFiles: build.query<IFilesResponse, IPhotoQuery>({
      query: (params) => ({
        url: PathBuilder.filesApi.fetchFilesByStaff(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    fetchFile: build.query<IFile, string>({
      query: (fileId) => ({
        url: PathBuilder.filesApi.fetchFileByStaff(fileId),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    updateFile: build.mutation<IFile, { params: FormData; _id: string }>({
      query: (data) => {
        const { params, _id } = data;

        return {
          url: PathBuilder.filesApi.updateFileByStaff(_id),
          method: RequestTypes.put,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: params,
        };
      },
      invalidatesTags: [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    deleteFile: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.filesApi.deleteFileByStaff(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    postFileByPatient: build.mutation<IMessageResponse, FormData>({
      query: (params) => ({
        url: PathBuilder.filesApi.postFileByPatient(),
        method: RequestTypes.post,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: params,
      }),
      invalidatesTags: [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    fetchFilesByPatient: build.query<IFilesResponse, IPhotoQuery>({
      query: (params) => ({
        url: PathBuilder.filesApi.fetchFilesByPatient(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
    fetchFileByPatient: build.query<IFile, string>({
      query: (fileId) => ({
        url: PathBuilder.filesApi.fetchFileByPatient(fileId),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.FILES_API_REDUCER_KEY],
    }),
  }),
});
