export const fileToBase64 = async (item: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item);

    reader.onload = (event) => {
      resolve(event?.target?.result as string);
    };

    reader.onerror = (event) => {
      console.log(`couldn't read the file`);
      reject(event?.target?.error);
    };
  });
