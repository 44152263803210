import axios, { InternalAxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { credential } from "../../shared";
import { TokenService } from "./tokenService";
import { WatchedRoutesEnum } from "./restClient.interfaces";

export const restClient = axios.create({
  baseURL: credential.apiUrl,
});

export const refreshAccessToken = async (): Promise<void> => {
  try {
    const { data } = await restClient({
      url: "/api/auth/refresh",
      method: "POST",
    });

    if (data?.refreshToken) {
      console.log("Refresh token success");

      TokenService.updateTokens(data);
      window.location.reload();
    }
  } catch (error) {
    // need to create log out or retry the same request
    console.log(`Update tokens error: ${(error as Error).message}`);
  }
};

// Request interceptor for API calls
restClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    newConfig.headers = {
      ...(TokenService.getAccessToken() && {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      }),
    } as AxiosRequestHeaders;

    if (newConfig.url === WatchedRoutesEnum.refresh) {
      newConfig.headers = {
        Authorization: `Bearer ${TokenService.getRefreshToken()}`,
      } as AxiosRequestHeaders;
    }

    return newConfig;
  },
  (error: unknown) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
restClient.interceptors.response.use(
  (response) => {
    const { data, config } = response;

    if (config.url === WatchedRoutesEnum.login) {
      TokenService.updateTokens(data);
      window.location.reload();
    }

    if (config.url === WatchedRoutesEnum.logout) {
      TokenService.deleteTokens();
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === WatchedRoutesEnum.refresh
    ) {
      TokenService.deleteTokens();
      throw new Error("Refresh token expired");
    }

    if (error.response.status === 401) {
      // if (originalRequest._retry) {
      //   console.log("401 error retry", originalRequest);

      //   TokenService.deleteTokens();
      //   return Promise.reject(error);
      // }

      // originalRequest._retry = true;

      if (TokenService.getAccessToken()) {
        await refreshAccessToken();
      }

      // axios.defaults.headers.common.Authorization = `Bearer  ${TokenService.getAccessToken()}`;

      // return restClient(originalRequest);
    }
    return Promise.reject(error);
  }
);
