// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalContentContainer_modal_content__ZGz8Y {
  background-color: #fff;
  width: 100%;
  padding: 30px 20px;
  background-color: #f5f7fa;
}
.modalContentContainer_modal_content__ZGz8Y:first-of-type {
  background-color: #fafbfd;
}
.modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI {
  text-align: center;
}
.modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_text__LuTXA {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
}
.modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_modal_title__Viym- {
  position: relative;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: #32976b;
  margin-bottom: 40px;
}
.modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_modal_title__Viym-::before {
  content: "";
  position: absolute;
  width: 100px;
  border-bottom: 1px solid #ccd0d9;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
}
@media (max-width: 1199px) {
  .modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_text__LuTXA {
    font-size: 18px;
  }
  .modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_modal_title__Viym- {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 991px) {
  .modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_text__LuTXA {
    font-size: 14px;
    line-height: 1;
  }
  .modalContentContainer_modal_content__ZGz8Y .modalContentContainer_modal_header__ByhVI .modalContentContainer_modal_title__Viym- {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/ModalContentContainer/modalContentContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAEI;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAAN;AAGI;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;AADN;AAGM;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,gCAAA;EACA,aAAA;EACA,SAAA;EACA,0BAAA;AADR;AAME;EAEI;IACE,eAAA;EALN;EAQI;IACE,eAAA;IACA,iBAAA;EANN;AACF;AAUE;EAEI;IACE,eAAA;IACA,cAAA;EATN;EAYI;IACE,eAAA;IACA,cAAA;IACA,mBAAA;EAVN;AACF","sourcesContent":[".modal_content {\n  background-color: #fff;\n  width: 100%;\n  padding: 30px 20px;\n  background-color: #f5f7fa;\n\n  &:first-of-type {\n    background-color: #fafbfd;\n  }\n\n  .modal_header {\n    text-align: center;\n\n    .text {\n      font-weight: 400;\n      font-size: 20px;\n      margin-bottom: 10px;\n    }\n\n    .modal_title {\n      position: relative;\n      font-weight: 700;\n      font-size: 36px;\n      line-height: 45px;\n      letter-spacing: -0.015em;\n      text-transform: uppercase;\n      color: #32976b;\n      margin-bottom: 40px;\n\n      &::before {\n        content: \"\";\n        position: absolute;\n        width: 100px;\n        border-bottom: 1px solid #ccd0d9;\n        bottom: -10px;\n        left: 50%;\n        transform: translate(-50%);\n      }\n    }\n  }\n\n  @media (max-width: 1199px) {\n    .modal_header {\n      .text {\n        font-size: 18px;\n      }\n\n      .modal_title {\n        font-size: 30px;\n        line-height: 35px;\n      }\n    }\n  }\n\n  @media (max-width: 991px) {\n    .modal_header {\n      .text {\n        font-size: 14px;\n        line-height: 1;\n      }\n\n      .modal_title {\n        font-size: 24px;\n        line-height: 1;\n        margin-bottom: 30px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_content": `modalContentContainer_modal_content__ZGz8Y`,
	"modal_header": `modalContentContainer_modal_header__ByhVI`,
	"text": `modalContentContainer_text__LuTXA`,
	"modal_title": `modalContentContainer_modal_title__Viym-`
};
export default ___CSS_LOADER_EXPORT___;
