import React, { FC } from "react";
import styles from "./adminMenuItem.module.scss";
import { IAdminMenu } from "./adminMenuItem.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AdminMenuItem: FC<IAdminMenu> = ({
  title,
  TitleIcon,
  items,
  isSidebarClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.sidebar_menu_wrap} ${
        isSidebarClose && styles.close_sidebar
      }`}
    >
      <div className={styles.title}>
        <div className={styles.menu_title_icon}>{<TitleIcon />}</div>
        <span>{t(title)}</span>
      </div>
      <ul>
        {items?.map(({ item, itemIcon: Icon, url, isActive }) => (
          <li
            key={item}
            className={`${styles.menu_item_wrap} ${
              location.pathname.includes(url as string) && styles.active_item
            }`}
            onClick={() => {
              navigate(`${url}`);
            }}
          >
            <div className={styles.menu_icon}>{<Icon />}</div>
            <span className={styles.menu_item}>{t(item)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
