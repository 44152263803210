// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alertNotification_alert_container__dsbKj {
  position: fixed;
  top: 70px;
  right: -500px;
  min-width: 250px;
  min-height: 50px;
  background-color: #d9faeb;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 3px #32976b;
  transition: right 0.5s ease-in-out;
  z-index: 101;
}
.alertNotification_alert_container__dsbKj .alertNotification_icon_wrap__oYVvm {
  padding: 5px;
}
.alertNotification_alert_container__dsbKj .alertNotification_icon_wrap__oYVvm .alertNotification_close_icon__fnBLF {
  font-size: 24px;
  line-height: 15px;
  color: #ccd0d9;
}
.alertNotification_alert_container__dsbKj .alertNotification_icon_wrap__oYVvm .alertNotification_close_icon__fnBLF:hover {
  color: #757677;
  cursor: pointer;
}
.alertNotification_alert_container__dsbKj .alertNotification_message_wrap__W57Zf {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}
.alertNotification_alert_container__dsbKj .alertNotification_message_wrap__W57Zf .alertNotification_alert_message__iDvaS {
  height: fit-content;
}

.alertNotification_show_alert__jo-HY {
  right: 20px;
  transition: right 0.5s ease-in-out;
  z-index: 101;
}

.alertNotification_error_alert__ncLXn {
  background-color: rgb(243, 123, 123);
}`, "",{"version":3,"sources":["webpack://./src/app/components/AlertNotification/alertNotification.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,oCAAA;EACA,kCAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;AACJ;AAAI;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAEN;AAAM;EACE,cAAA;EACA,eAAA;AAER;AAGE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AADJ;AAGI;EACE,mBAAA;AADN;;AAMA;EACE,WAAA;EACA,kCAAA;EACA,YAAA;AAHF;;AAMA;EACE,oCAAA;AAHF","sourcesContent":[".alert_container {\n  position: fixed;\n  top: 70px;\n  right: -500px;\n  min-width: 250px;\n  min-height: 50px;\n  background-color: #d9faeb;\n  display: flex;\n  justify-content: space-between;\n  border-radius: 5px;\n  box-shadow: 0px 0px 15px 3px #32976b;\n  transition: right 0.5s ease-in-out;\n  z-index: 101;\n\n  .icon_wrap {\n    padding: 5px;\n    .close_icon {\n      font-size: 24px;\n      line-height: 15px;\n      color: #ccd0d9;\n\n      &:hover {\n        color: #757677;\n        cursor: pointer;\n      }\n    }\n  }\n\n  .message_wrap {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0px 10px;\n\n    .alert_message {\n      height: fit-content;\n    }\n  }\n}\n\n.show_alert {\n  right: 20px;\n  transition: right 0.5s ease-in-out;\n  z-index: 101;\n}\n\n.error_alert {\n  background-color: rgb(243, 123, 123);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert_container": `alertNotification_alert_container__dsbKj`,
	"icon_wrap": `alertNotification_icon_wrap__oYVvm`,
	"close_icon": `alertNotification_close_icon__fnBLF`,
	"message_wrap": `alertNotification_message_wrap__W57Zf`,
	"alert_message": `alertNotification_alert_message__iDvaS`,
	"show_alert": `alertNotification_show_alert__jo-HY`,
	"error_alert": `alertNotification_error_alert__ncLXn`
};
export default ___CSS_LOADER_EXPORT___;
