import React, { FC } from "react";
import i18n from "i18next";
import { Button } from "../Buttons/Button";
import styles from "./languagMenu.module.scss";
import { changeLocale } from "../../shared/i18n";

export interface ILanguageMenu {
  onMenuItemClick?: () => void;
}

export const LanguageMenu: FC<ILanguageMenu> = ({ onMenuItemClick }) => {
  return (
    <div className={styles.language_menu}>
      <Button
        onClick={() => {
          onMenuItemClick && onMenuItemClick();
          changeLocale("pl");
        }}
        label="PL"
        className="round_button"
        customStyle={i18n.resolvedLanguage === "pl" && styles.active}
      />
      <Button
        onClick={() => {
          onMenuItemClick && onMenuItemClick();
          changeLocale("uk");
        }}
        label="UA"
        className="round_button"
        customStyle={i18n.resolvedLanguage === "uk" && styles.active}
      />
      <Button
        onClick={() => {
          onMenuItemClick && onMenuItemClick();
          changeLocale("en");
        }}
        label="EN"
        className="round_button"
        customStyle={i18n.resolvedLanguage === "en" && styles.active}
      />
    </div>
  );
};
