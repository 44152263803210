import React, { FC } from "react";
import styles from "./adminSidebar.module.scss";
import { logo } from "@jat-med/assets";
import { IAdminSidebar } from "./adminSidebar.interface";
import { AdminMenuItem } from "../../AdminPanel/AdminMenuItem";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../../shared";

export const AdminSidebar: FC<IAdminSidebar> = ({
  sidebarMenuData,
  isSidebarClose,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${styles.sidebar_container} ${
        isSidebarClose && styles.close_sidebar
      }`}
    >
      <div className={styles.logo_wrap}>
        <img
          src={logo}
          alt="logo"
          onClick={() => navigate(`${RoutesEnum.initialRoute}`)}
        />
        <p className={styles.logo_title}>Jatmed</p>
      </div>
      {sidebarMenuData.map((menuItem) => (
        <AdminMenuItem
          key={menuItem.title}
          title={menuItem.title}
          TitleIcon={menuItem.TitleIcon}
          items={menuItem.items}
          isSidebarClose={isSidebarClose}
        />
      ))}
    </div>
  );
};
