import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  IMessageResponse,
  IStageListResponse,
  IStageQueueDto,
  IStageQueueListResponse,
  credential,
} from "../../shared";

export const STAGES_API_REDUCER_KEY = "stagesApi";

export const stageApi = createApi({
  reducerPath: STAGES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [STAGES_API_REDUCER_KEY],
  endpoints: (build) => ({
    fetchStageList: build.query<IStageListResponse, void>({
      query: () => ({
        url: PathBuilder.stageApi.fetchStageList(),
        method: RequestTypes.get,
      }),
      providesTags: () => [STAGES_API_REDUCER_KEY],
    }),
    fetchStageQueue: build.query<IStageQueueListResponse, string>({
      query: (patientId) => ({
        url: PathBuilder.stageApi.fetchStageQueue(patientId),
        method: RequestTypes.get,
      }),
      providesTags: () => [STAGES_API_REDUCER_KEY],
    }),
    createStageQueue: build.mutation<IMessageResponse, IStageQueueDto>({
      query: (params) => ({
        url: PathBuilder.stageApi.createStageQueue(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [STAGES_API_REDUCER_KEY],
    }),
    deleteStageQueue: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.stageApi.deleteStageQueue(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [STAGES_API_REDUCER_KEY],
    }),
  }),
});
