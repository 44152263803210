// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminPanelHeader_admin_header_wrap__c7\\+bo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_left_part__-WqbQ {
  display: flex;
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_left_part__-WqbQ .adminPanelHeader_icon_menu__qA6T0 {
  display: flex;
  width: 30px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_left_part__-WqbQ .adminPanelHeader_icon_menu__qA6T0 img {
  width: 100%;
  cursor: pointer;
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_left_part__-WqbQ .adminPanelHeader_rotate_icon__sMlGI {
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_right_part__jCtMB {
  display: flex;
}
@media screen and (max-width: 767px) {
  .adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_right_part__jCtMB {
    display: none;
  }
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_language_dropdown__pGnRE {
  font-size: 14px;
  border: 2px solid #32976b;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 25px;
  position: relative;
}
.adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_language_dropdown__pGnRE div {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  display: flex;
}
@media (min-width: 767px) {
  .adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_language_dropdown__pGnRE {
    display: none;
  }
}
@media (max-width: 991px) {
  .adminPanelHeader_admin_header_wrap__c7\\+bo .adminPanelHeader_left_part__-WqbQ .adminPanelHeader_icon_menu__qA6T0 {
    width: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/AdminPanel/AdminPanelHeader/adminPanelHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;AACJ;AACI;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,sCAAA;AACN;AACM;EACE,WAAA;EACA,eAAA;AACR;AAGI;EACE,wBAAA;EACA,gCAAA;AADN;AAKE;EACE,aAAA;AAHJ;AAKI;EAHF;IAII,aAAA;EAFJ;AACF;AAIE;EACE,eAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAII;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,iBAAA;EACA,aAAA;AAFN;AAKI;EAlBF;IAmBI,aAAA;EAFJ;AACF;AAKE;EAEI;IACE,WAAA;EAJN;AACF","sourcesContent":[".admin_header_wrap {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n\n  .left_part {\n    display: flex;\n\n    .icon_menu {\n      display: flex;\n      width: 30px;\n      transform: rotate(0deg);\n      transition: transform 0.3s ease-in-out;\n\n      img {\n        width: 100%;\n        cursor: pointer;\n      }\n    }\n\n    .rotate_icon {\n      transform: rotate(90deg);\n      transition: all 0.3s ease-in-out;\n    }\n  }\n\n  .right_part {\n    display: flex;\n\n    @media screen and (max-width: 767px) {\n      display: none;\n    }\n  }\n  .language_dropdown {\n    font-size: 14px;\n    border: 2px solid #32976b;\n    display: flex;\n    align-items: center;\n    padding: 5px;\n    border-radius: 25px;\n    position: relative;\n\n    div {\n      position: absolute;\n      top: 40px;\n      left: 50%;\n      transform: translateX(-50%);\n      background: white;\n      display: flex;\n    }\n\n    @media (min-width: 767px) {\n      display: none;\n    }\n  }\n\n  @media (max-width: 991px) {\n    .left_part {\n      .icon_menu {\n        width: 20px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_header_wrap": `adminPanelHeader_admin_header_wrap__c7+bo`,
	"left_part": `adminPanelHeader_left_part__-WqbQ`,
	"icon_menu": `adminPanelHeader_icon_menu__qA6T0`,
	"rotate_icon": `adminPanelHeader_rotate_icon__sMlGI`,
	"right_part": `adminPanelHeader_right_part__jCtMB`,
	"language_dropdown": `adminPanelHeader_language_dropdown__pGnRE`
};
export default ___CSS_LOADER_EXPORT___;
