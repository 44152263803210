import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserState } from "../types";
import { IUser } from "../../shared";

const initialState: IUserState = {
  currentUser: undefined,
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUser | undefined>) => ({
      ...state,
      currentUser: action.payload,
    }),
    removeCurrentUser: (state) => ({
      ...state,
      currentUser: initialState.currentUser,
    }),
  },
});

export const { setCurrentUser, removeCurrentUser } = userSlice.actions;

export default userSlice.reducer;
