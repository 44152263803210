import moment from "moment";
import { ISchedule } from "../types";

export const getUnscheduleDates = (
  startDate: Date,
  endDate: Date,
  schedule: ISchedule[]
) => {
  const currentDate = new Date(startDate.getTime());
  const rangeOfDates = [];
  while (currentDate <= endDate) {
    rangeOfDates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  const scheduleMap = schedule?.reduce(
    (accumulator: Record<string, any>, schedule) => {
      const date = moment(schedule?.startAt).format("DD.MM.YYYY");
      accumulator[date] = schedule;
      return accumulator;
    },
    {}
  );

  const unscheduleDates = rangeOfDates.filter(
    (date) => !scheduleMap[moment(date).format("DD.MM.YYYY")]
  );
  return unscheduleDates;
};
