import formatStringByPattern from "format-string-by-pattern";
import {
  composeValidators,
  isCorrectPesel,
  isEmail,
  isPhoneNumber,
  isRequired,
} from "../../helpers";

import { userRolesLocales } from "../userRoles";

export const userForm = [
  {
    name: "login",
    placeholder: "entriesForms.loginPlaceholder",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "password",
    placeholder: "entriesForms.passwordPlaceholder",
    type: "password",
    component: "input",
    validate: isRequired,
  },
];

export const orserCallForm = [
  {
    name: "name",
    placeholder: "entriesForms.firstNamePlaceholder",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "phoneNumber",
    placeholder: "entriesForms.phonePlaceholder",
    type: "tel",
    component: "input",
    validate: composeValidators(isRequired, isPhoneNumber),
  },
];

export const userFormSignIn = [
  {
    name: "firstName",
    placeholder: "entriesForms.firstNamePlaceholder",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "lastName",
    placeholder: "entriesForms.lastNamePlaceholder",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "phoneNumber",
    placeholder: "entriesForms.phonePlaceholder",
    type: "tel",
    component: "input",
    validate: composeValidators(isRequired, isPhoneNumber),
    // parse: formatStringByPattern(phoneMask),
  },
  {
    name: "email",
    placeholder: "entriesForms.emailPlaceholder",
    type: "text",
    component: "input",
    // validate: composeValidators(isEmail),
  },
  {
    name: "idCode",
    placeholder: "entriesForms.idCodePlaceholder",
    type: "text",
    validate: isCorrectPesel,
    component: "input",
    parse: formatStringByPattern("99999999999"),
  },
  {
    name: "password",
    placeholder: "entriesForms.passwordPlaceholder",
    type: "password",
    component: "input",
    validate: isRequired,
  },
];

export const adminCreateUserForm = [
  {
    name: "firstName",
    placeholder: "adminPanel.form.firstNamePlaceholder",
    label: "adminPanel.form.firstNameLabel",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "lastName",
    placeholder: "adminPanel.form.lastNamePlaceholder",
    label: "adminPanel.form.lastNameLabel",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "role",
    label: "adminPanel.form.userTypeLabel",
    component: "select",
    options: userRolesLocales,
  },
  {
    name: "phoneNumber",
    placeholder: "adminPanel.form.phonePlaceholder",
    label: "adminPanel.form.phoneLabel",
    type: "tel",
    component: "input",
    validate: composeValidators(isRequired, isPhoneNumber),
    // parse: formatStringByPattern(phoneMask),
  },
  {
    name: "email",
    placeholder: "adminPanel.form.emailPlaceholder",
    label: "adminPanel.form.emailLabel",
    type: "text",
    component: "input",
    // validate: composeValidators(isRequired, isEmail),
  },
  {
    name: "idCode",
    placeholder: "adminPanel.form.idCodePlaceholder",
    label: "adminPanel.form.idCodeLabel",
    type: "text",
    validate: isCorrectPesel,
    component: "input",
    parse: formatStringByPattern("99999999999"),
  },
  {
    name: "city",
    placeholder: "adminPanel.form.cityPlaceholder",
    label: "adminPanel.form.cityLabel",
    type: "text",
    component: "input",
  },
  {
    name: "street",
    placeholder: "adminPanel.form.streetPlaceholder",
    label: "adminPanel.form.streetLabel",
    type: "text",
    component: "input",
  },
  // {
  //   name: "language",
  //   label: "adminPanel.form.communicationLanguage",
  //   component: "select",
  //   options: communicationLanguages,
  // },
  {
    name: "Photo",
    placeholder: "adminPanel.form.photoLabel",
    label: "adminPanel.form.photoLabel",
    type: "file",
  },
];

export const adminUserProfileForm = [
  {
    name: "firstName",
    placeholder: "adminPanel.form.firstNamePlaceholder",
    label: "adminPanel.form.firstNameLabel",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "lastName",
    placeholder: "adminPanel.form.lastNamePlaceholder",
    label: "adminPanel.form.lastNameLabel",
    type: "text",
    component: "input",
    validate: isRequired,
  },

  {
    name: "phoneNumber",
    placeholder: "adminPanel.form.phonePlaceholder",
    label: "adminPanel.form.phoneLabel",
    type: "tel",
    component: "input",
    validate: composeValidators(isRequired, isPhoneNumber),
    // parse: formatStringByPattern(phoneMask),
  },
  {
    name: "email",
    placeholder: "adminPanel.form.emailPlaceholder",
    label: "adminPanel.form.emailLabel",
    type: "text",
    component: "input",
    // validate: composeValidators(isRequired, isEmail),
  },
  {
    name: "city",
    placeholder: "adminPanel.form.cityPlaceholder",
    label: "adminPanel.form.cityLabel",
    type: "text",
    component: "input",
  },
  {
    name: "street",
    placeholder: "adminPanel.form.streetPlaceholder",
    label: "adminPanel.form.streetLabel",
    type: "text",
    component: "input",
  },
  {
    name: "idCode",
    placeholder: "adminPanel.form.idCodePlaceholder",
    label: "adminPanel.form.idCodeLabel",
    type: "text",
    validate: isCorrectPesel,
    component: "input",
    parse: formatStringByPattern("99999999999"),
  },
];

export const adminUpdatePatientForm = [
  {
    name: "firstName",
    placeholder: "adminPanel.form.firstNamePlaceholder",
    label: "adminPanel.form.firstNameLabel",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "lastName",
    placeholder: "adminPanel.form.lastNamePlaceholder",
    label: "adminPanel.form.lastNameLabel",
    type: "text",
    component: "input",
    validate: isRequired,
  },
  {
    name: "email",
    placeholder: "adminPanel.form.emailPlaceholder",
    label: "adminPanel.form.emailLabel",
    type: "text",
    component: "input",
    // validate: composeValidators(isRequired, isEmail),
  },
  {
    name: "phoneNumber",
    placeholder: "adminPanel.form.phonePlaceholder",
    label: "adminPanel.form.phoneLabel",
    type: "tel",
    component: "input",
    validate: composeValidators(isRequired, isPhoneNumber),
  },
  {
    name: "city",
    placeholder: "adminPanel.form.cityPlaceholder",
    label: "adminPanel.form.cityLabel",
    type: "text",
    component: "input",
  },
  {
    name: "street",
    placeholder: "adminPanel.form.streetPlaceholder",
    label: "adminPanel.form.streetLabel",
    type: "text",
    component: "input",
  },
  {
    name: "idCode",
    placeholder: "adminPanel.form.idCodePlaceholder",
    label: "adminPanel.form.idCodeLabel",
    type: "text",
    validate: isCorrectPesel,
    component: "input",
    parse: formatStringByPattern("99999999999"),
  },
];
