export const dailyExerciseApi = {
  fetchDailyExercise: (): string =>
    `/api/portal/staff/exercises/daily-exercises`,
  updateDailyExercise: (id: string): string =>
    `/api/portal/staff/medical/exercises/daily-exercises/${id}`,
  fetchDailyPatientExercise: (): string =>
    `/api/portal/patient/exercises/daily-exercises`,
  updateDailyPatientExercise: (id: string): string =>
    `/api/portal/patient/exercises/daily-exercises/${id}`,
};
