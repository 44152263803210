export const Faqs = [
  {
    question: "faqs.1.question",
    answer: "faqs.1.answer",
  },
  {
    question: "faqs.2.question",
    answer: "faqs.2.answer",
  },
  {
    question: "faqs.3.question",
    answer: "faqs.3.answer",
  },
  {
    question: "faqs.4.question",
    answer: "faqs.4.answer",
  },
  {
    question: "faqs.5.question",
    answer: "faqs.5.answer",
  },
  {
    question: "faqs.6.question",
    answer: "faqs.6.answer",
  },
  {
    question: "faqs.7.question",
    answer: "faqs.7.answer",
  },
  {
    question: "faqs.8.question",
    answer: "faqs.8.answer",
  },
  {
    question: "faqs.9.question",
    answer: "faqs.9.answer",
  },
];
