import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PathBuilder, RequestTypes, axiosBaseQuery } from "../../utils";
import {
  ICreateExercisesTableDto,
  IExercisesTableDataResponse,
  IMessageResponse,
  IUpdateExercisesTableDto,
  credential,
} from "../../shared";
import { ReducerKeys } from "../types";

export const exerciseApi = createApi({
  reducerPath: ReducerKeys.EXERCISES_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.EXERCISES_API_REDUCER_KEY],
  endpoints: (build) => ({
    createExercisesTable: build.mutation<
      IMessageResponse,
      ICreateExercisesTableDto
    >({
      query: (params) => ({
        url: PathBuilder.exerciseApi.createExercisesTable(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.EXERCISES_API_REDUCER_KEY],
    }),
    fetchExercisesTable: build.query<
      IExercisesTableDataResponse,
      { patientId: string; isHistory: boolean }
    >({
      query: ({ patientId: id, isHistory }) => ({
        url: PathBuilder.exerciseApi.fetchExercisesTable(id),
        method: RequestTypes.get,
        params: { isHistory },
      }),
      providesTags: () => [ReducerKeys.EXERCISES_API_REDUCER_KEY],
    }),
    fetchOwnExercisesTable: build.query<
      IExercisesTableDataResponse,
      { isHistory: boolean }
    >({
      query: (params) => ({
        url: PathBuilder.exerciseApi.fetchOwnExercisesTable(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.EXERCISES_API_REDUCER_KEY],
    }),
    updateExercisesTable: build.mutation<
      IMessageResponse,
      IUpdateExercisesTableDto
    >({
      query: (params) => {
        const { _id: id, ...newParams } = params;
        return {
          url: PathBuilder.exerciseApi.updateExercisesTable(id),
          method: RequestTypes.put,
          data: newParams,
        };
      },
      invalidatesTags: [ReducerKeys.EXERCISES_API_REDUCER_KEY],
    }),
    deleteExercisesTable: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.exerciseApi.deleteExercisesTable(id),
        method: RequestTypes.delete,
      }),
      invalidatesTags: [ReducerKeys.EXERCISES_API_REDUCER_KEY],
    }),
  }),
});
