import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons";
import { RoutesEnum } from "../../shared";
import styles from "./404.module.scss";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrap}>
      <h1>404</h1>
      <p>Page not found</p>
      <Button
        className="green_outlined"
        onClick={() => {
          navigate(`${RoutesEnum.initialRoute}`);
        }}
        label="Go back to home"
      />
    </div>
  );
};
