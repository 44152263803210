export enum ArticleTypes {
  NEWS = "news",
  POST = "post",
}

export const articleTypesLocales = [
  { value: "news", title: "articleTypesLocales.news" },
  { value: "post", title: "articleTypesLocales.post" },
];

export const articlesPerPagePublic = 9;
