export const placeApi = {
  createPlace: (): string => `/api/admin/places`,
  updatePlace: (placeId: string): string => `/api/admin/places/${placeId}`,
  fetchPlaceList: (): string => `/api/public/places`,
  fetchPlace: (placeId: string): string => `/api/public/places/${placeId}`,
  activatePlace: (placeId: string): string =>
    `/api/admin/places/${placeId}/activate`,
  deactivatePlace: (placeId: string): string =>
    `/api/admin/places/${placeId}/deactivate`,
};
