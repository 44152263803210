import { ISchedule, IVisitResponse } from "../types";
import { getEventsPerHour } from "./getEventsPerHour";

export const getShortNameWithEventsCount = (
  schedule: ISchedule,
  value?: Date,
  eventsPerDay?: IVisitResponse[]
) => {
  return `${schedule?.user?.lastName.slice(
    0,
    5
  )} ${schedule?.user?.firstName.slice(0, 1)}. -
      ${
        getEventsPerHour(value, eventsPerDay)?.filter((event) => {
          return event.doctorId === schedule?.user?._id;
        }).length
      }`;
};
