export const aboutDiseaseList = [
  "Дефекти постави, сколіоз, плоскостопість, інші порушення функції стопи, набута кривошия, дисплазія кульшових суглобів.",
  "Вроджені та набуті захворювання у дітей.",
  "Наслідки травм та захворювань опорно-рухового апарату (у т. ч. після переломів, ендопротезування, артроскопії).",
  "Біль спини, захворювання хребта.",
  "Порушення діяльності дихальної системи: обструктивні захворювання (бронхіальна астма, обструктивний бронхіт, ХОЗЛ), емфізема, стани після операцій на легенях, пневмоній та плевритів.",
  "Стани після мастектомії.",
  "Відновлення порушених рухових функцій та навчання переміщенням і самообслуговуванню після перенесеного інсульту, черепно- та спинномозкової травми.",
  "Консультації фахівців фізичної терапії, які включають реабілітаційне обстеження, встановлення реабілітаційного діагнозу, визначення основних реабілітаційних проблем та завдань, рекомендації щодо засобів та методів фізичної терапії.",
  "Створення індивідуальної програми фізичної терапії з поточним контролем за фізичним станом та її корекцією.",
  "Індивідуальні заняття з фізичної терапії для дорослих та дітей, які включають увесь необхідний спектр засобів фізичної терапії.",
  "Виготовлення індивідуальних ортопедичних устілок.",
  "Визначення потреби в ортопедичних засобах і технічних засобах реабілітації їх індивідуальний підбір.",
];
