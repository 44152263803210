export const plHeadersName: Record<string, string> = {
  number: "pozycja",
  date: "Data",
  bloodPressureBefore: "Ciśnienie krwi przed",
  pulseBefore: "Puls przed",
  bloodPressureAfter: "Ciśnienie krwi po",
  pulseAfter: "Puls po",
  angle: "Kąt",
  caterpillarLegsBent: "Gąsienica - nogi zgięte",
  caterpillarLegsStraight: "Gąsienica - nogi proste",
  backPressure: "Przyciśniecie pleców",
  oneLegSwings: "Wahania 1 nogą",
  swingsTest: "Próba wahania",
  swingsTwoLegs: "Wahania 2 nogami",
  booklet: "Książeczka",
  frog: "Żabka",
  bicycle: "Rower",
  legLift: "Wyciąg za noga",
  weigh: "Waż (w dół i w górę)",
  stick: "Kij (przód bok)",
  cobra: "Szyja",
  downhillSkiPelvisUp: "Zjazd narty miednica do góry",
  swallow: "Jaskółka",
  swallowWithHead: "Jaskółka z głową",
  swallowLegsOnly: "Jaskółka tylko nogi",
  swallowHARD: "Jaskółka HARD 45st.",
  frogHARD: "Żabka HARD 45st.",
  runningOnTheBoard45P: "Bieg na desce 45 P",
  runningOnTheBoard45B: "Bieg na desce 45 B",
  wingsStatic: "Skrzydła (statyczne)",
  wingsDynamic: "Skrzydła (dynamiczne)",
  butterfly: "Motyl  (ciężarki do boku)",
  bat: "Nietoperz (ręce do uszu)",
};
